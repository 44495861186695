@import url("material-ui-font.css");
@import url("material-ui-icons.css");

body {
  margin: 0;
  background-color: #eff1f4;
  font-family: roboto,Montserrat;
}
.header1 {
  font-weight: 500;
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
}
.header2 {
  font-weight: 300;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
}
.body1 {
  font-weight: 700;
  margin-bottom: 0.25rem;
  font-size: 1rem;
}
